<script setup lang="ts">
import type { PropType } from 'vue';

const props = defineProps({
  auctions: { type: Object as PropType<any>, required: true },
  pending: Boolean as PropType<boolean>,
});
const { auctions, pending } = toRefs(props);
</script>
<template>
  <div class="w-full">
    <div
      class="grid w-full grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-6 md:grid-cols-3 md:gap-6 xl:grid-cols-4 xl:gap-8"
    >
      <NftCard v-for="nft in auctions" :key="`${nft.id}${nft.auction?.id}`" :nft="nft" />
      <template v-if="pending">
        <NftCard v-for="index in 12" :key="index" :nft="undefined" />
      </template>
    </div>
  </div>
</template>
